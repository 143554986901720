import { Button, Popover } from 'antd';
import { useRef } from 'react';
import { BiEditAlt } from "react-icons/bi";
import { BsReply } from "react-icons/bs";
import { FaRegGrinAlt } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { MdOutlineContentCopy } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { TiArrowForwardOutline } from "react-icons/ti";
import useOutsideAlerter from '../../../hooks/useOutsideAlerter';

const MessageOption = (props) => {
  const { align, deleteMessage, message, copyToClipboard, isDelete, makeReaction, reactVisible, setReactVisible, optionVisible, setOptionVisible, userProfile } = props;


  return (
    <div className="message-options">
      <Popover
        zIndex={0}
        visible={optionVisible}
        placement={align === "left" ? "bottomLeft" : "bottomRight"}
        className="message-option"
        content={<MessageOptions
          deleteMessage={deleteMessage}
          copyToClipboard={copyToClipboard}
          setOptionVisible={setOptionVisible}
          isDelete={isDelete}
          message={message} />}
        trigger="click">
        <Button onClick={() => setOptionVisible((prev) => !prev)} type="text">
          <HiDotsVertical />
        </Button>
      </Popover>

      {
        ((userProfile.id === message?.senderId) || (userProfile.id === message?.user?.id)) ?
          ""
          :
          <Popover
            visible={reactVisible}
            zIndex={0}
            placement={align === "left" ? "right" : "left"}
            content={<MessageReact
              setReactVisible={setReactVisible}
              makeReaction={makeReaction}
              message={message} />}
            trigger="click">
            <Button onClick={() => setReactVisible((pre) => !pre)} type="text">
              <FaRegGrinAlt />
            </Button>
          </Popover>
      }
    </div>
  );
};

export default MessageOption;


const MessageOptions = ({ deleteMessage, message, copyToClipboard, isDelete, setOptionVisible }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setOptionVisible);

  return (
    <div className="message-options-popover" ref={wrapperRef}>
      <Button type="text">
        <BsReply />
        <span style={{ marginLeft: '20px' }}>Reply Message</span>
      </Button>
      {

      }
      <Button type="text" onClick={() => copyToClipboard(message.content)}>
        <MdOutlineContentCopy />
        <span style={{ marginLeft: '20px' }}>Copy Message</span>
      </Button>
      {
        isDelete &&
        <Button type="text">
          <BiEditAlt />
          <span style={{ marginLeft: '20px' }}>Edit Message</span>
        </Button>
      }
      <Button type="text">
        <TiArrowForwardOutline />
        <span style={{ marginLeft: '20px' }}>Forward Message</span>
      </Button>
      {
        isDelete &&
        <Button type="text" onClick={() => deleteMessage(message.id)}>
          <RiDeleteBinLine />
          <span style={{ marginLeft: '20px', color: 'red' }}>Remove Message</span>
        </Button>
      }
    </div>
  );
};


const MessageReact = ({ makeReaction, message, setReactVisible }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setReactVisible);
  return (
    <div className="message-react" ref={wrapperRef}>
      <button className="icon" onClick={() => makeReaction(message, 1)}>👍</button>
      <button className="icon" onClick={() => makeReaction(message, 2)}>❤️</button>
      <button className="icon" onClick={() => makeReaction(message, 3)}>😁</button>
      <button className="icon" onClick={() => makeReaction(message, 4)}>😮</button>
      <button className="icon" onClick={() => makeReaction(message, 5)}>😢</button>
      <button className="icon" onClick={() => makeReaction(message, 6)}>😠</button>
    </div>
  );
};