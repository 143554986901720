import { Button, Drawer } from 'antd';
import { useState } from 'react';
import { BiSearch } from "react-icons/bi";
import { IoIosCall } from "react-icons/io";
import { IoCloseOutline, IoPersonCircleSharp, IoVideocam } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { activeTimeFormat } from '../../../utils/timeFormat';
import CustomAvatar from '../../helper/CustomAvatar';
import ProfileInfo from '../../helper/ProfileInfo';
import TextAvatar from '../../helper/TextAvatar';

const ChattingHeader = (props) => {
  const { currentUserProfile, isOnline } = props;
  const [visibleProfile, setVisibleProfile] = useState(false);

  const showDrawer = () => {
    setVisibleProfile(true);
  };

  const onClose = () => {
    setVisibleProfile(false);
  };

  return (
    <>
      <div className="chatting-header">
        <div className="user-info">
          {
            (currentUserProfile?.profileImageResize || currentUserProfile?.groupImage) ?
              <CustomAvatar
                size={40}
                icon={isOnline && "small"}
                src={currentUserProfile?.profileImageResize || currentUserProfile?.groupImage}
              />
              :
              <TextAvatar name={currentUserProfile?.fullname || currentUserProfile.name || ""}
                icon={isOnline && "small"}
                size="40px" fontSize="18px" />
          }
          <div>
            <p className="user-name">{currentUserProfile?.fullname || currentUserProfile.name || ""}</p>
            <p className="user-status">
              {isOnline ? "Active Now" : (currentUserProfile?.lastLoggedin && ("Last seen " + activeTimeFormat(currentUserProfile?.lastLoggedin)))}
            </p>
          </div>
        </div>

        <div className="header-icons">
          <div>
            <Button type="text">
              <BiSearch />
            </Button>
            <Button type="text" onClick={showDrawer}>
              <IoPersonCircleSharp />
            </Button>
            <Button type="text">
              <IoVideocam />
            </Button>
            <Button type="text">
              <IoIosCall />
            </Button>
          </div>
          <div className="cross-icon">
            <Link to='/'>
              <Button type="text">
                <IoCloseOutline />
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Drawer
        title="Profile Info"
        placement="right"
        className="group-profile"
        width={250}
        onClose={onClose}
        visible={visibleProfile}
      >
        <ProfileInfo userProfile={currentUserProfile} />
      </Drawer>
    </>
  );
};

export default ChattingHeader;