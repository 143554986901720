import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { saveNotificationApi } from "../api/chat";
import { firebaseConfig } from "./firebase.config";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

initializeApp(firebaseConfig);

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);



export const onSubscribe = () => {
  Notification.requestPermission().then(async (permission) => {

  }).catch(err => console.log(err));
}


export const requestForToken = (userId) => {
  return getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY }).then((currentToken) => {
    if (currentToken) {
      console.log(currentToken);
      saveToken(currentToken, userId)
    } else {
      console.log('No registration token available. Request permission to generate one.');
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
};


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });

/**
   * get current user all messages function
   */
const saveToken = async (token, userId) => {
  const tokenData = {
    notificationId: token,
  }

  async function successHandler(response) {
    const res = await response.json();
    console.log(res)
  }

  async function handleBadReq(response) {
    const res = await response.json();
    console.log(res)
  }
  return await saveNotificationApi(userId, tokenData, { successHandler, handleBadReq }
  )
}