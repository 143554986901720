import { Button, Divider, Switch } from 'antd';
import React from 'react';
import CustomAvatar from './CustomAvatar';
import TextAvatar from './TextAvatar';

const ProfileInfo = ({ userProfile }) => {

  return (
    <div>
      <div className="user-profile-info">
        <div className="user-profile-avatar">
          {
            (userProfile?.profileImageResize) ?
              <CustomAvatar
                size={68}
                src={userProfile?.profileImageResize}
              />
              :
              <TextAvatar name={userProfile.fullname || ""}
                size="68px" fontSize="30px" />
          }
        </div>
        <p className="user-profile-name">{userProfile.fullname}</p>
        <p className="user-profile-email">{userProfile.email}</p>
      </div>
      <Divider className="profile-divider" />
      <div className="user-profile-options">
        <div className="user-profile-switch">
          <p className="user-profile-switch-title">Notification </p>
          <Switch size="small" />
        </div>
        <div className="user-profile-switch">
          <p className="user-profile-switch-title">Mute</p>
          <Switch size="small" />
        </div>
        <div className="user-profile-switch">
          <p className="user-profile-switch-title">Ignore conversation</p>
          <Switch size="small" />
        </div>
      </div>
      <Divider className="profile-divider" />
      <div className="user-profile-function">
        <Button type="text">Clear conversation</Button>
        <Button type="text">Delate conversation</Button>
        <Button type="text">Block </Button>
      </div>
    </div >
  );
};


export default ProfileInfo;