import { Button, Divider, Modal, Switch } from "antd";
import { useState } from 'react';
import AddMember from "../../container/group/AddMember";
import CustomAvatar from "./CustomAvatar";
import TextAvatar from "./TextAvatar";

const GroupDrawer = ({ groupProfile }) => {
  const [isAddMemberVisible, setIsAddMemberVisible] = useState(false);

  const showAddMemberModal = () => {
    setIsAddMemberVisible(true);
  };

  const cancelAddMemberModal = () => {
    setIsAddMemberVisible(false);
  };


  return (
    <div>
      <div className="group-profile-info">
        <div className="group-profile-avatar">
          {
            (groupProfile?.groupImage) ?
              <CustomAvatar
                size={68}
                src={groupProfile?.groupImage}
              />
              :
              <TextAvatar name={groupProfile.name || ""}
                size="68px" fontSize="30px" />
          }
        </div>
        <p className="group-profile-name">{groupProfile.name}</p>
      </div>
      <Divider className="profile-divider" />
      <div className="user-profile-options">
        <div className="user-profile-switch">
          <p className="user-profile-switch-title">Notification </p>
          <Switch size="small" />
        </div>
        <div className="user-profile-switch">
          <p className="user-profile-switch-title">Mute</p>
          <Switch size="small" />
        </div>
        <div className="user-profile-switch">
          <p className="user-profile-switch-title">Ignore conversation</p>
          <Switch size="small" />
        </div>
      </div>
      <Divider className="profile-divider" />
      <div className="user-profile-function">
        <Button onClick={showAddMemberModal} type="text">Add New Member</Button>
        <Button type="text">Clear conversation</Button>
        <Button type="text">Delate conversation</Button>
        <Button type="text">Block </Button>
      </div>
      <Modal
        visible={isAddMemberVisible}
        className="add-member-modal"
        closable={false}
        footer={null}
        width="auto"
      >
        <AddMember cancelAddMemberModal={cancelAddMemberModal} groupProfile={groupProfile} />
      </Modal>
    </div>
  );
};

export default GroupDrawer;