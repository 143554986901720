import { Form, message, Spin } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { confirmOtpMessage, resetLoginSession, sendOtpMessage, updatePassword, userLoginApi } from '../../api/auth';
import { setUser, setUserProfile } from '../../redux/features/authSlice';
import { selectLoading, updateLoading } from '../../redux/features/layoutSlice';
import LoginErrorModal from '../../ui/auth/login/LoginErrorModal';
import LoginUi from '../../ui/auth/login/LoginUi';

const Login = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [isErrorModal, setIsErrorModal] = useState(false);
  const [isMultipleLogin, setIsMultipleLogin] = useState(false);
  const [modalNumber, setModalNumber] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const loading = useSelector(selectLoading);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // All function for handle login page modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalNumber(1);
  };

  const showErrorModal = () => {
    setIsErrorModal(true);
  };

  const handleOk = () => {
    form.resetFields(["password"]);
    setIsErrorModal(false);
    setIsMultipleLogin(false);
  };

  const onFinishModal = async (values) => {
    setEmail(values.forgotEmail);
    const randomNumber = Math.floor(Math.random() * (9999 - 1000 + 1) + 1000);
    const mailData = {
      email: values.forgotEmail,
      randomNumber: randomNumber
    }

    // on success handler function
    async function successHandler(response) {
      let res = await response.json();
      setModalNumber(2)
    }

    // Bad Request Handler function
    async function handleBadReq(response) {
      let err = await response.json();
      setModalNumber(1)
      setIsModalVisible(false);
      message.error(err.message);
    }

    return await sendOtpMessage(mailData, {
      successHandler,
      handleBadReq,
    });
  };

  const onFinishPassword = async (values) => {
    console.log(values);
    const password = values.confirmPass;
    const mailData = {
      email: email,
      newpass: password
    }
    // on success handler function
    async function successHandler(response) {
      let res = await response.json();
      message.success(res.message);
      setModalNumber(1)
      handleCancel()
    }

    // Bad Request Handler function
    async function handleBadReq(response) {
      let err = await response.json();
      message.error(err.message);
    }

    return await updatePassword(mailData, {
      successHandler,
      handleBadReq,
    });
  };

  // function for handle otp
  const onFinishOtp = async (values) => {
    const otp = parseInt(values.otp);
    const mailData = {
      email: email,
      randomNumber: otp
    }
    // on success handler function
    async function successHandler(response) {
      let res = await response.json();
      setModalNumber(3)
    }

    // Bad Request Handler function
    async function handleBadReq(response) {
      let err = await response.json();
      message.error(err.message);
    }

    return await confirmOtpMessage(mailData, {
      successHandler,
      handleBadReq,
    });
  };

  // Action after loggin function
  const onLogginHandler = (res) => {
    const userLoginData = res.profile;
    const accessToken = res.accessToken;
    dispatch(updateLoading(false));
    message.success('User logged in successfully !');
    dispatch(setUserProfile(userLoginData))
    dispatch(setUser(accessToken))
    navigate('/');
  }

  // on login submit function
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const email = form.getFieldValue("email")
    const password = form.getFieldValue("password")
    if (!email) return;

    dispatch(updateLoading(true));
    const loginData = {
      email: email,
      password: password,
    }

    // on Login success handler function
    async function successHandler(response) {
      let res = await response.json();
      console.log(res)
      onLogginHandler(res);
    }

    // Bad Request Handler function
    async function handleBadReq(response) {
      let err = await response.json();
      console.log(err)
      if (err?.loggedIn) {
        setIsMultipleLogin(err.loggedIn);
      }
      const message = err.message;
      setErrorMessage(message);
      // console.log("Login Error", err.message);
      dispatch(updateLoading(false));
      showErrorModal();
    }

    return await userLoginApi(loginData, {
      successHandler,
      handleBadReq,
    });
  };

  // on login submit function
  const handleLogginSession = async () => {
    const email = form.getFieldValue("email");
    const mailData = {
      email: email,
    }

    // on Login success handler function
    async function successHandler(response) {
      let res = await response.json();
      console.log(res)
      onLogginHandler(res);
    }

    // Bad Request Handler function
    async function handleBadReq(response) {
      let err = await response.json();
      console.log(err);
      message.error("Somthing went wrong!")
    }

    return await resetLoginSession(mailData, {
      successHandler,
      handleBadReq,
    });
  };


  return (
    <>
      <LoginErrorModal
        isErrorModal={isErrorModal}
        isMultipleLogin={isMultipleLogin}
        handleLogginSession={handleLogginSession}
        handleOk={handleOk}
        errorMessage={errorMessage}
      />
      <Spin spinning={loading} delay={100}>
        <LoginUi
          form={form}
          onSubmitHandler={onSubmitHandler}
          onFinishModal={onFinishModal}
          isModalVisible={isModalVisible}
          showModal={showModal}
          handleCancel={handleCancel}
          modalNumber={modalNumber}
          onFinishOtp={onFinishOtp}
          onFinishPassword={onFinishPassword}
        />
      </Spin>
    </>
  );
};

export default Login;