import { groupNotificationApi, singleNotificationApi } from "../api/chat";

// send notificatio to single user
export const sendSingleNotification = async (chatId, message) => {
  async function successHandler(response) {
    const res = await response.json();
    // console.log(res)
  }

  async function handleBadReq(response) {
  }
  return await singleNotificationApi(chatId, message, { successHandler, handleBadReq })
}

// send notificatio to group all users
export const sendGroupNotification = async (groupId, message) => {
  async function successHandler(response) {
    const res = await response.json();
    // console.log(res)
  }

  async function handleBadReq(response) {
  }
  return await groupNotificationApi(groupId, message, { successHandler, handleBadReq })
}