import connector from "../connector";

export const creategroupApi = async function (payload, options = {}) {
  const url = '3000/group/creategroup';
  return await connector.post(url, payload, options);
};

export const getGroupInfo = async function (groupId, userId, options = {}) {
  const url = `3000/group/${groupId}/${userId}`;
  return await connector.get(url, options);
};

export const getGroupMessagesApi = async function (groupId, pageNumber, payload, options = {}) {
  const url = `3000/group/${groupId}/groupmessages?page=${pageNumber}`;
  return await connector.post(url, payload, options);
};

export const groupMessageSendApi = async function (groupId, payload, options = {}) {
  const url = `3000/group/${groupId}/sendMessageGroup`;
  return await connector.post(url, payload, options);
};

export const groupMessageSeenApi = async function (groupId, payload, options = {}) {
  const url = `3000/group/${groupId}/markAsRead`;
  return await connector.post(url, payload, options);
};

export const groupMessageDeleteApi = async function (msgId, groupId, userId, options = {}) {
  const url = `3000/group/deletemessageGroup/${msgId}/${groupId}/${userId}`;
  return await connector.delete(url, options);
};

export const groupReactionApi = async function (userId, payload, options = {}) {
  const url = `3000/like-emoji/${userId}/voteGroup`;
  return await connector.post(url, payload, options);
};

export const addMemberApi = async function (payload, options = {}) {
  const url = '3000/group/addMember';
  return await connector.post(url, payload, options);
};