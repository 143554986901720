import { message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import socketio from "socket.io-client";
import { checkJWTToken } from '../api/auth';
import { resetUserData, selectCurrentUser, selectUserProfile, selectUserToken } from '../redux/features/authSlice';
import { setUpdateConversation, updateConversationGroupMessage } from '../redux/features/layoutSlice';
import HomeUi from '../ui/home/HomeUi';


const HomePage = () => {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userProfile = useSelector(selectUserProfile);
  const token = useSelector(selectUserToken);
  const [newSocket, initSocket] = useState(null);

  // Check JWT token validity function
  const checkJWTTokenValidity = useCallback(async () => {
    const payload = {
      token: token,
    }
    async function successHandler(response) {
      const res = await response.json();
      console.log(res)
      if (!res.message) {
        dispatch(resetUserData())
        navigate('/login');
      }
    }

    async function handleBadReq(response) {
      let error = await response.json();
      message.error(error.message);
      // console.log(error.message);
    }

    return await checkJWTToken(payload, { successHandler, handleBadReq })
  }, [token, dispatch, navigate])


  useEffect(() => {
    const userId = userProfile.id;
    if (userProfile && newSocket) {
      newSocket.connect();

      newSocket.on('newMessagesidebar/user/' + userId, (msg) => {
        // Play the sound.
        let unreadCount = 0;
        if (parseInt(msg.senderId) === parseInt(currentUser)) {
          unreadCount = 0;
        } else {
          unreadCount = msg.unread;
        }
        const newMessage = {
          users_id: msg.senderId,
          image: msg.senderImage,
          name: msg.senderName,
          lastMessage: msg?.content,
          lastMessageTime: msg?.createdAt,
          unreadMessages: unreadCount,
          status: 'seen',
          type: "single"
        }
        dispatch(setUpdateConversation(newMessage))
      })

      newSocket.on('newMessagesidebar/group/' + userId, (res) => {
        // console.log(res);
        const newMessage = {
          lastMessage: res?.content,
          groupId: res?.roomId,
          lastMessageTime: res?.createdAt,
          name: res?.groupName,
          image: res?.groupImg,
          unreadMessages: res?.unread,
          type: "group",
          status: "unseen",
          users_seen: []
        }
        dispatch(updateConversationGroupMessage(newMessage))
      })
    }

    return () => {
      if (newSocket) {
        newSocket.close();
        newSocket.off('newMessagesidebar/user/' + userId)
        newSocket.off('newMessagesidebar/group/' + userId)
      }
    };
  }, [dispatch, newSocket, userProfile, currentUser])

  // All useEffect function below
  useEffect(() => {
    checkJWTTokenValidity();
  }, [checkJWTTokenValidity]);

  useEffect(() => {
    if (token) {
      const newSocket = socketio(`${process.env.REACT_APP_BASE_URL}:3000`, {
        transports: ['websocket'],
        query: {
          token: token
        }
      })
      initSocket(newSocket);
    }
  }, [token])

  return (
    <HomeUi
      newSocket={newSocket}
    />
  );
};

export default HomePage;