import { message } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { addMemberApi } from '../../api/group';
import { selectFriendList } from '../../redux/features/layoutSlice';
import AddMemberGroup from '../../ui/modal/AddMemberGroup';

const AddMember = ({ cancelAddMemberModal, groupProfile }) => {
  const friendList = useSelector(selectFriendList);
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState(friendList);
  const [selectedUser, setSelectedUser] = useState([]);
  const groupId = groupProfile.id;

  const addUserOnClick = (id) => {
    if (selectedUser.includes(id)) {
      const newList = selectedUser.filter((item) => item !== id);
      setSelectedUser(newList);
    } else {
      setSelectedUser((prev) => [...prev, id]);
    }
  }

  const handleChangeUserSearch = (e) => {
    const value = e.target.value;
    const searchResult = friendList.filter(user => user["fullname"].toLowerCase().includes(value.toLowerCase()));
    setUserList(searchResult);
  }


  // Handle edit profileImage function
  const addMemberToGroup = async () => {
    setLoading(true);
    const usersData = {
      userId: selectedUser,
      groupId: groupId,
    }

    async function successHandler(response) {
      const res = await response.json();
      message.success(res.message);
      setLoading(false);
      cancelAddMemberModal();
    }

    async function handleBadReq(response) {
      let error = await response.json();
      setLoading(false);
      message.error(error.message);
    }

    return await addMemberApi(usersData, { successHandler, handleBadReq })
  };

  return (
    <AddMemberGroup
      cancelAddMemberModal={cancelAddMemberModal}
      handleChangeUserSearch={handleChangeUserSearch}
      selectedUser={selectedUser}
      addUserOnClick={addUserOnClick}
      userList={userList}
      loading={loading}
      addMemberToGroup={addMemberToGroup}
    />
  );
};

export default AddMember;