import { Affix, Layout } from 'antd';
import { useRef } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../../container/sidebar/Sidebar';
const { Sider, Content } = Layout;

const HomeUi = (props) => {
  const { newSocket } = props;
  const sideBarRef = useRef(null)

  return (
    <>
      <Affix offsetTop={0}>
        <Sider
          width={460}
          className="sider-component"
          trigger={sideBarRef?.current}
          collapsed={false}
        >
          <Sidebar newSocket={newSocket} />
        </Sider>
      </Affix>
      <Content className="content-area">
        <Outlet context={newSocket} />
      </Content>
    </>
  );
};

export default HomeUi;